/**
 * @Author: Calvin Green <calvin>
 * @Date:   2021-02-27T22:24:49+02:00
 * @Email:  calvin@diversitude.com
 * @Last modified by:   calvin
 * @Last modified time: 2021-05-12T21:18:53+02:00
 * @Copyright: Diversitude 2021
 */

export const menuItems = [
    {
        id: 1,
        label: "menuitems.menu.text",
    
        isTitle: true
    },
    {
        id: 2,
        label: "Dashboard",
        icon: "dripicons-home",
        link: "/"
    },
    {
        id: 9,
        label: "TestZone",
        icon: "dripicons-rocket",
        link: "/testzone"
    },
    {
        id: 10,
        label: "Plugin Test",
        icon: "dripicons-view-apps",
        link: "/plugin"
    },
    {
        id: 11,
        label: "Forms Test",
        icon: "dripicons-document",
        link: "/form-builder"
    },

];
